import styled from 'styled-components';

import Facebook from '../Icons/Facebook'
import Instagram from '../Icons/Instagram'
import Twitter from '../Icons/Twitter'
import LinkedIn from '../Icons/LinkedIn'
import Discord from '../Icons/Discord'
import Pinterest from '../Icons/Pinterest'

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;

&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`

const Bottom = styled.div`
width: 75%;
margin: 50px auto;
display: flex;
justify-content: space-between;
align-items: center;
color:#333333;
border-top:2px solid #555555;
padding-top:50px;
a{
  text-decoration:underline;
}
@media (max-width: 48em) {
flex-direction: column;
width: 100%;

span{
  margin-bottom: 1rem;
}
}
`
const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: 4rem;
color: var(--secondary);
transition: all 0.2s ease;
cursor: pointer;
&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size:4rem;

}
`

const Footer = () => {
      
	  return (
	  <Bottom>
	  	   <a href="/" style={{ textDecoration: 'none'}}><LogoText>E.</LogoText></a>
	   <IconList>
      <a href="http://facebook.com/ehbcnft" target='_blank' 
      rel="noopener noreferrer"
      aria-label='facebook'
      >
        <Facebook />
      </a>
      <a href="https://www.instagram.com/ehbcnft/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='instagram'
      >
        <Instagram />
      </a>
      <a href="https://twitter.com/ehbcnft" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <Twitter />
      </a>
      <a href="https://www.linkedin.com/company/ehbcnft/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='linkedIn'
      >
        <LinkedIn />
      </a>
	  <a href="https://in.pinterest.com/ehbcnft/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='pinterest'
      >
        <Pinterest />
      </a> 
	  <a href="https://discord.gg/VaPCHjyM6Z" target='_blank' 
      rel="noopener noreferrer"
      aria-label='discord'
      >
        <Discord />
      </a>  
    </IconList>
        <span>
          &copy; {new Date().getFullYear()} <b>EHBCNFT</b>. All rights reserved.
        </span>
        <span style={{marginLeft: '5px'}}>
          Made with &#10084; by <b>EHBCNFT</b>.
        </span>
      </Bottom>
	  )}
	  
	  export default Footer