import React,{useState} from 'react'
import styled, { keyframes } from 'styled-components';
import Loading from '../components/Loading';
import RoundTextBlack from '../assets/Rounded-Text-Black.png';

const Section = styled.section`
width: 100vw;
`

const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;

width: 85%;
height:4rem;
margin: 0 auto;

.mobile{
  display: none;
}

@media (max-width: 64em) {
.desktop{
  display: none;
}
.mobile{
  display: inline-block;
}

}

`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;


@media (max-width: 64em) {
/* 1024 px */



position: fixed;
top: ${props => props.theme.navHeight};
left: 0;
right: 0;
bottom: 0;
width: 100vw;
height: ${props => `calc(100vh - ${props.theme.navHeight})`};
z-index: 2;
background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
backdrop-filter: blur(2px);

transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
transition: all 0.3s ease;
flex-direction: column;
justify-content: center;
touch-action: none;
}

`

const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`
const Round = styled.div`
position: fixed;
bottom: 2rem;
right: 90%;
width: 6rem;
height: 6rem;
border: 1px solid #333333;
border-radius: 50%;
z-index:1;


img{
  width: 100%;
  height: auto;
  animation: ${rotate} 6s linear infinite reverse ;
}
@media (max-width: 64em) {
  width: 4rem;
  height: 4rem;
  left: none;
  right: 2rem;
  bottom: 100%;
}
@media (max-width: 48em) {
  
  right: 1rem;
}
`

const Circle = styled.span`
width: 3rem;
height: 3rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);

background-color: #333;
color:#FFF;
font-size:2rem;

@media (max-width: 64em) {
  width: 2rem;
  height: 2rem;
font-size:${props => props.theme.fontlg};
}
`

const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: 4rem;
color: #FFFFFF;
transition: all 0.2s ease;
cursor: pointer;
&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size:4rem;

}
`

const Navigation = () => {

  const [click, setClick] = useState(false);
  
const scrollTo = (id) => {

  let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })

  setClick(!click);
}

return (
    
    <Section id="navigation">
	  <Round>
        <Circle>
          &#x2193; 
        </Circle>
          <img width={500} height={400} src={RoundTextBlack} alt="EHBCNFT" />
        </Round>
    </Section>
  )
}

export default Navigation